import { css } from '@emotion/react';
import { THEME } from '@/shared/constants';
import { useSelectItem } from '@/shared/hooks';
import { TikTokProductCategory } from '@/graphql';
import { Radio, TextCutter, Icon } from '@/shared/atoms';
import { useTikTokProductCategories } from '@/shared/organisms';

type ProductCategoriesListProps = ReturnType<
  typeof useSelectItem<Partial<Pick<TikTokProductCategory, 'id' | 'packageId'>>>
>;

export const ProductCategoriesList = ({ selectedItem, setSelectedItem }: ProductCategoriesListProps) => {
  const { productCategories } = useTikTokProductCategories();

  return (
    <div css={styles.wrapper}>
      {productCategories.map((productCategory) => {
        const { id, icon, name, packageId } = productCategory;

        return (
          <div key={id} css={styles.itemSizing}>
            <Radio
              css={styles.item}
              checked={selectedItem?.id === id}
              onClick={() => setSelectedItem({ id, packageId })}
              label={
                <>
                  <TextCutter css={{ flex: 1, fontSize: '14px', lineHeight: '100%' }} text={name} />
                  <Icon icon={icon} size={24} />
                </>
              }
            />
          </div>
        );
      })}
    </div>
  );
};
const styles = {
  wrapper: css({
    '--item-per-row': 3,
    '--item-gap-row': '8px',
    '--item-gap-column': '8px',

    display: 'grid',
    gridAutoRows: '1fr',
    gridTemplateColumns: 'repeat(var(--item-per-row), 1fr)',
    margin: 'calc(var(--item-gap-row) * -1) calc(var(--item-gap-column) * -1)'
  }),
  itemSizing: css({
    boxSizing: 'border-box',
    flexBasis: 'calc(100% / var(--item-per-row))',
    padding: 'var(--item-gap-row) var(--item-gap-column)'
  }),
  planItemSizing: css({
    boxSizing: 'border-box',
    marginTop: 'var(--item-gap-row)',
    flexBasis: 'calc(100% / var(--item-per-row))',
    '&:not(:nth-of-type(3n + 1)': { borderLeft: THEME.border.base }
  }),
  item: css({
    gap: '8px',
    height: '80px',
    display: 'flex',
    alignItems: 'center',
    padding: '24px 16px',
    borderRadius: '12px',
    boxSizing: 'border-box',
    border: THEME.border.base,
    transition: 'box-shadow 0.15s ease-in-out',
    backgroundColor: THEME.background.colors.white,
    '&:hover': { boxShadow: '0px 5px 15px 1.5px rgba(0,0,0,0.3)' }
  })
};
