import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { THEME } from '@/shared/constants';
import { YoutubeCmsChannelDetail } from '@/graphql';
import { localizedDateFormatter } from '@/shared/utils';
import { useGenerateInfluencerProfileLink } from '@/shared/hooks';
import { useUpdateYoutubeChannelShareRate } from '@/shared/organisms';
import { Button, TextCutter, MediaPreview, InputNumberFormatter, Label, Anchor } from '@/shared/atoms';
import { useYoutubeCMSContext } from '../YoutubeCMSContext';

interface YoutubeCMSChannelInformationProps {
  cmsChannelId: string;
  data: YoutubeCmsChannelDetail;
}

export const YoutubeCMSChannelInformation = ({ data, cmsChannelId }: YoutubeCMSChannelInformationProps) => {
  const { t } = useTranslation();
  const { canModifyYoutubeCMSData } = useYoutubeCMSContext();
  const { generateProfileLink } = useGenerateInfluencerProfileLink();
  const { hasRowChanged, handleEnter, handleRowChange, handleSaveChange, saving } = useUpdateYoutubeChannelShareRate();

  const profileLink = generateProfileLink(
    data.influencerId,
    data.socialAccountId ? { filter: { id: String(data.socialAccountId ?? '') } } : undefined
  );

  return (
    <div css={styles.channelInfo}>
      <div css={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        <MediaPreview src={data.avatar} css={{ width: '56px', height: '56px', borderRadius: '4px' }} />
        <div>
          <p css={{ fontSize: '16px', lineHeight: '100%', fontWeight: 600 }}>{data.channelName || '-'}</p>

          {profileLink.to ? (
            <Anchor
              variant="blue"
              label={t('Influencer account')}
              css={{ marginTop: '8px', fontSize: '12px', lineHeight: '100%' }}
              {...profileLink}
            />
          ) : null}

          <p css={{ marginTop: '8px', fontSize: '12px', lineHeight: '100%', color: THEME.text.colors.gray.lv3 }}>
            {t('id')}:&nbsp;{data.channelId || '-'}
          </p>
        </div>
      </div>

      <div>
        <Label title={t('TextForm.Channel Marginrate')} />

        <div css={{ display: 'flex', gap: '8px' }}>
          <InputNumberFormatter
            unit="%"
            format="percent"
            placeholder="50"
            onKeyPress={handleEnter}
            css={{ width: '200px' }}
            disabled={!canModifyYoutubeCMSData || saving}
            value={String(data.shareRate ?? '')}
            onChange={(value) => {
              if (canModifyYoutubeCMSData) {
                handleRowChange({
                  value,
                  id: Number(cmsChannelId),
                  channelId: data.channelId,
                  defaultValue: String(data.shareRate) ?? ''
                });
              }
            }}
          />

          <Button
            variant="blue"
            loading={saving}
            title={t('Button.Save')}
            onClick={handleSaveChange}
            css={{ padding: '0 24px' }}
            disabled={!canModifyYoutubeCMSData || !hasRowChanged}
          />
        </div>

        <TextCutter
          css={{ color: THEME.text.colors.gray.lv3, fontSize: '12px', marginTop: '2px' }}
          text={`${t('Last Updated').toLowerCase()}: ${
            data.updatedDate ? localizedDateFormatter(data.updatedDate, 'yyyy/MM/dd') : '-'
          }`}
        />
      </div>
    </div>
  );
};
const styles = {
  channelInfo: css({
    gap: '16px',
    display: 'flex',
    padding: '24px 16px',
    alignItems: 'center',
    boxSizing: 'border-box',
    justifyContent: 'space-between',
    backgroundColor: THEME.background.colors.white,
    borderBottom: `1px solid ${THEME.border.colors.gray.lv1}`
  })
};
