import { css } from '@emotion/react';
import { Trans } from 'react-i18next';
import { useInfluencerPermissions, useUserRoles } from '@/auth';
import {
  SocialAccountType,
  SocialAccountStatus,
  useGetInfluencerRateCardQuery,
  useRequestInfluencersProfilePptxMutation,
  InfluencerNewProfileHeaderV2
} from '@/graphql';
import { Button, ButtonLink, SocialIcon, Status, TextCutter } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useQueryHelper, useTranslateCountry } from '@/shared/hooks';
import { InfluencerAvatar } from '@/shared/molecules';
import { bigIntFormatter, formatPrice, getGenderString } from '@/shared/utils';
import { PostPrices } from './PostPrices';
import { SocialMediasWithNumber } from './SocialMediasWithNumber';
import { Tags } from './Tags';
import { getSnsFollowerOptions } from './utils';

export interface InfluencerProfileIntroProps {
  influencerProfile: InfluencerNewProfileHeaderV2;
  objectFit?: 'contain' | 'cover';
  isEngagementProposal?: boolean;
}

export const InfluencerProfileIntro = ({ influencerProfile, isEngagementProposal }: InfluencerProfileIntroProps) => {
  const { isAdvertiser, isAgency } = useUserRoles();
  const { id, age, tags, gender, isTaggedToCompany, country, name, status, avatar, followersCount, isBrandAccount } =
    influencerProfile;
  // https://bitbucket.org/adasiaholdings/casting_asia_advertiser_frontend/pull-requests/2474
  const canEditProfile = isEngagementProposal
    ? isAdvertiser || isAgency
      ? false
      : isTaggedToCompany !== false
    : isTaggedToCompany !== false;

  const { tc } = useTranslateCountry();
  const { t, enqueueSnackbar } = useQueryHelper();
  const { hideEditInfluencerBtn } = useInfluencerPermissions();
  const { data } = useGetInfluencerRateCardQuery({ skip: !canEditProfile, variables: { input: { influencerId: id } } });
  const { loading, callRequestInfluencersProfilePptx } = useRequestInfluencersProfilePptxMutation();

  const rateCard = data?.getInfluencerRateCard;
  const douyinRateCard = rateCard?.douyin;
  const xhsRateCard = rateCard?.xhs;
  const isSignedUp = status === SocialAccountStatus.SIGNED_UP;

  const handleDownload = async () => {
    try {
      await callRequestInfluencersProfilePptx({
        variables: { format: 'PPTX', influencerIds: [id], socialMedias: Object.keys(SocialAccountType) }
      });
      enqueueSnackbar(t('succeededInRequest'), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(t('failedToDownload'), { variant: 'error' });
    }
  };

  return (
    <div css={styles.wrapper}>
      <div css={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <InfluencerAvatar src={avatar} css={{ borderRadius: '50%' }} size="124px" objectFit="cover" />
        <TextCutter text={name} css={{ marginTop: '16px', fontSize: '20px', fontWeight: 600 }} />
        {isSignedUp && <Status variant="LIGHT_GREEN" css={styles.status} label={t('Signed Up')} />}
        {isBrandAccount && <Status variant="LIGHT_GREY" css={styles.status} label={t('Option.Brand')} />}

        <div css={styles.ageGender}>
          {age ? (
            <Trans
              values={{ age }}
              i18nKey="ageWithYearsOld"
              components={{ span: <span css={{ fontSize: 12, color: THEME.text.colors.gray.lv3 }} /> }}
            />
          ) : (
            '-'
          )}
          <TextCutter text={`${t(`Option.${getGenderString(gender)}`)} ${tc(country.name)}`} />
        </div>
      </div>

      <hr css={styles.hr} />
      <div css={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div css={{ fontWeight: 600, fontSize: '12px' }}>{t('General.Followers')}</div>
        <div css={{ fontWeight: 600, fontSize: '18px' }}>{bigIntFormatter(followersCount)}</div>
      </div>
      <SocialMediasWithNumber currency={rateCard?.currencyId} data={getSnsFollowerOptions(influencerProfile)} css={{ marginTop: '16px' }} />

      <hr css={styles.hr} />
      <Tags tags={tags} influencerId={id} />

      {canEditProfile ? (
        <>
          <hr css={styles.hr} />
          <PostPrices influencerId={id} data={rateCard} />

          {influencerProfile.douyinAccounts?.length && douyinRateCard?.length ? (
            <div css={{ marginTop: '16px', fontSize: '10px', lineHeight: '100%' }}>
              <div css={{ display: 'flex', alignItems: 'center' }}>
                <div css={{ fontWeight: 600 }}>{t('Detailed post price for')}</div>
                <SocialIcon type="DOUYIN" size={16} css={{ marginLeft: 'auto' }} />
                {rateCard?.currencyId ? <span>({rateCard?.currencyId})</span> : null}
              </div>
              {douyinRateCard.map((item, index) => (
                <div
                  key={`douyin-details-${index}`}
                  css={{ marginTop: '8px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                >
                  <p>{t(item.description)}:</p>
                  <p>{formatPrice(item.price, rateCard?.currencyId)}</p>
                </div>
              ))}
            </div>
          ) : null}
          {influencerProfile.xhsAccounts?.length && xhsRateCard?.length ? (
            <div css={{ marginTop: '16px', fontSize: '10px', lineHeight: '100%' }}>
              <div css={{ display: 'flex', alignItems: 'center' }}>
                <div css={{ fontWeight: 600 }}>{t('Detailed post price for')}</div>
                <SocialIcon type="XHS" size={16} css={{ marginLeft: 'auto' }} />
                {rateCard?.currencyId ? <span>({rateCard?.currencyId})</span> : null}
              </div>
              {xhsRateCard.map((item, index) => (
                <div
                  key={`xhs-details-${index}`}
                  css={{ marginTop: '8px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                >
                  <p>{t(item.description)}:</p>
                  <p>{formatPrice(item.price, rateCard?.currencyId)}</p>
                </div>
              ))}
            </div>
          ) : null}
        </>
      ) : null}

      <hr css={styles.hr} />
      <div css={css({ display: 'flex', gap: '8px', justifyContent: 'flex-start' })}>
        {canEditProfile && !hideEditInfluencerBtn ? (
          <ButtonLink
            variant="white"
            css={{ flex: 1 }}
            title={t('Button.Edit Profile')}
            to="/influencer/$id"
            params={{ id: id.toString() }}
          />
        ) : null}
        <Button
          variant="white"
          loading={loading}
          onClick={handleDownload}
          prefixIcon={{ icon: 'download', size: '14px' }}
          tooltip={{ help: t("Download this influencer's all social accounts") }}
        />
      </div>
    </div>
  );
};

const styles = {
  wrapper: css({
    flex: 'none',
    height: 'max-content',
    boxSizing: 'border-box',
    padding: '24px 16px 16px',
    borderBottomLeftRadius: '3px',
    lineHeight: 'calc(1em + 1ex)',
    borderBottomRightRadius: '3px',
    color: THEME.text.colors.black.lv1,
    width: THEME.container.influencerProfile.intro.width,
    backgroundColor: THEME.background.colors.white,
    boxShadow: '0 1px 2px 0 #dee5ec, 0 0 0 1px rgba(222, 229, 236, 0.5)'
  }),
  hr: css({ width: '100%', height: '1px', margin: '16px 0', backgroundColor: '#EEF3F7' }),
  status: css({
    fontSize: 10,
    height: '18px',
    marginTop: '8px',
    padding: '0 8px',
    width: 'max-content',
    borderRadius: '35px'
  }),
  ageGender: css({
    gap: '4px',
    fontWeight: 400,
    display: 'flex',
    fontSize: '14px',
    marginTop: '16px',
    alignItems: 'baseline',
    justifyContent: 'center'
  })
};
