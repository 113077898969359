import { Trans } from 'react-i18next';
import { ReactNode, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { Accordion } from '@mantine/core';
import { useFormContext, UseFormSetValue } from 'react-hook-form';
import Lottie from 'react-lottie';
import { marked } from 'marked';
import DOMPurify from 'dompurify';
import { Button, Icon } from '@/shared/atoms';
import { gradients, setPseudoGradientBorder, THEME } from '@/shared/constants';
import { TextAreaField, TextField } from '@/shared/molecules';
import { starsImageAnimation } from '@/shared/assets';
import {
  GenerateMarketplaceCampaignDetailStatus,
  GetGeneratedMarketplaceCampaignDetailPayloadGeneratedDetail,
  MarketplaceCampaignStatus,
  useGenerateMarketplaceCampaignDetailMutation,
  useGetGeneratedMarketplaceCampaignDetailQuery
} from '@/graphql';
import { useQueryHelper } from '@/shared/hooks';
import { MarketplaceFormKeys, MarketplaceFormValues } from '../MarketplaceCampaignForms';
import { AiRainbowSpinner } from './Spinner';
import { DescriptionGeneratorStateReturnType, useValidateDescriptionFields } from './hooks';

interface DescriptionGeneratorProps extends DescriptionGeneratorStateReturnType {
  children?: ReactNode;
}
export const DescriptionGenerator = ({
  loading,
  children,
  progress,
  onClear,
  setStatus,
  onStartLoading,
  setContentForce
}: DescriptionGeneratorProps) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { watch, setValue } = useFormContext<MarketplaceFormValues>();
  const values = watch();
  const { handleValidateDescriptionInputs, isFormValid, descriptionErrors, resetErrors } =
    useValidateDescriptionFields();

  const [requestId, setRequestId] = useState('');
  const [openSection, setOpenSection] = useState('');
  const [hasGeneratedContent, setHasGeneratedContent] = useState(false);

  const { callGenerateMarketplaceCampaignDetail } = useGenerateMarketplaceCampaignDetailMutation({
    onCompleted: ({ generateMarketplaceCampaignDetail }) => {
      if (generateMarketplaceCampaignDetail) {
        setRequestId(generateMarketplaceCampaignDetail.requestId);
        startPolling(5000); // 5s seems fine now, but we can change if needed
        setStatus('loading');
      }
    }
  });
  const { startPolling, stopPolling, data } = useGetGeneratedMarketplaceCampaignDetailQuery({
    skip: !requestId,
    variables: {
      input: {
        requestId
      }
    },
    onError: (err) => {
      setRequestId('');
      stopPolling();
      setStatus('resolved');
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  });

  useEffect(() => {
    const detailsResponse = data?.getGeneratedMarketplaceCampaignDetail;
    if (detailsResponse) {
      if (detailsResponse.status === GenerateMarketplaceCampaignDetailStatus.SUCCEEDED) {
        stopPolling();
        enqueueSnackbar(t('Campaign description was successfully generated'), { variant: 'success' });
        setRequestId('');
        onClear();
        setStatus('resolved');
        setHasGeneratedContent(true);
        if (detailsResponse.generatedDetail) {
          setMarkdownFieldsAsync(detailsResponse.generatedDetail, setValue, setContentForce);
        }
      } else if (detailsResponse.status === GenerateMarketplaceCampaignDetailStatus.ERROR) {
        stopPolling();
        enqueueSnackbar(t('Sorry, we are experiencing server issues Please try again later'), { variant: 'error' });
        setRequestId('');
        onClear();
        setStatus('resolved');
      }
    }
  }, [
    t,
    onClear,
    setValue,
    setStatus,
    stopPolling,
    setRequestId,
    setContentForce,
    enqueueSnackbar,
    data?.getGeneratedMarketplaceCampaignDetail
  ]);

  const hasSelectedCountry = values.countryId !== '';
  const isSupportedByStatus = [MarketplaceCampaignStatus.REVIEWING].includes(values.status); // initially campaign status is also reviewing, but actually it is draft
  useEffect(() => {
    if (!hasSelectedCountry && isSupportedByStatus && hasGeneratedContent) {
      setRequestId('');
      stopPolling();
      setStatus('idle');
      setOpenSection('');
      setMarkdownFieldsAsync({ requirement: '', serviceInformation: '', title: '' }, setValue, setContentForce);
    }

    return () => {
      setRequestId('');
      stopPolling();
      setStatus('idle');
    };
  }, [hasSelectedCountry, isSupportedByStatus, hasGeneratedContent]);

  const handleStartGeneration = async () => {
    const hasErrors = handleValidateDescriptionInputs(values);

    if (!hasErrors) {
      onStartLoading();
      try {
        await callGenerateMarketplaceCampaignDetail({
          variables: {
            input: {
              countryId: values.countryId,
              campaignType: values.campaignType,
              companyUrl: values.descriptionGenerator_websiteUrl,
              productUrl: values.descriptionGenerator_productUrl,
              prompt: values.descriptionGenerator_additionalInstructions
            }
          }
        });
      } catch (_) {
        onClear();
      }
    }
  };

  return (
    <div>
      <Accordion unstyled multiple disableChevronRotation value={[openSection]}>
        <Accordion.Item key="button" value="button">
          <div css={styles.heading}>
            {children}
            <Button
              variant="anyAiGradient"
              title={
                <div css={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                  {!hasSelectedCountry || !isSupportedByStatus ? (
                    <Icon icon="ai-icon" size={16} color={THEME.text.colors.gray.lv1} />
                  ) : (
                    <Lottie
                      width={16}
                      height={16}
                      style={{
                        margin: 'unset'
                      }}
                      options={{
                        loop: true,
                        autoplay: true,
                        animationData: starsImageAnimation
                      }}
                    />
                  )}
                  {t('Button.Detail Generator')}
                </div>
              }
              onClick={() => setOpenSection(openSection ? '' : 'button')}
              {...(!hasSelectedCountry
                ? {
                    disabled: true,
                    tooltip: {
                      help: t<string>('Tooltip.Please select a country first')
                    }
                  }
                : !isSupportedByStatus
                  ? { disabled: true }
                  : {})}
            >
              <div css={openSection && styles.outlineMask} />
            </Button>
          </div>
          <Accordion.Panel>
            {
              <div css={styles.accordionContentBorder}>
                <div css={styles.accordionContent}>
                  <div css={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <div css={styles.generatorIconBg}>
                      <Icon icon="ai-icon" size={16} color="#fff" css={{ margin: 'auto' }} />
                    </div>
                    <span css={{ fontSize: '16px', fontWeight: 600 }}>{t('Campaign Detail Generator')}</span>
                  </div>
                  <p css={{ fontSize: '14px', color: THEME.text.colors.gray.lv3 }}>
                    <Trans
                      i18nKey={'DescriptionGeneratorInfo.firstRow'}
                      components={{ span: <span css={{ color: THEME.text.colors.black.lv1, fontWeight: 600 }} /> }}
                    />
                    <br />
                    <Trans
                      i18nKey={'DescriptionGeneratorInfo.secondRow'}
                      components={{ span: <span css={{ color: THEME.text.colors.black.lv1 }} /> }}
                    />
                    <br />
                    <Trans i18nKey={'DescriptionGeneratorInfo.thirdRow'} />
                  </p>

                  <div css={{ display: 'flex', columnGap: '14px' }}>
                    <TextField<keyof MarketplaceFormValues>
                      name={MarketplaceFormKeys.descriptionGenerator_productUrl}
                      title={t('TextForm.Product URL')}
                      css={{ width: '306px', flex: 'unset', padding: 0 }}
                      placeholder="https://korealcollection.com/collections..."
                      customError={descriptionErrors.productUrl}
                      onChangeCallback={() => resetErrors('productUrl')}
                    />
                    <TextField
                      name={MarketplaceFormKeys.descriptionGenerator_websiteUrl}
                      title={t('TextForm.Company Website URL')}
                      css={{ width: '306px', flex: 'unset', padding: 0 }}
                      placeholder="https://anymindgroup.com/"
                      disabled={!values.descriptionGenerator_productUrl}
                      customError={descriptionErrors.websiteUrl}
                      onChangeCallback={() => resetErrors('websiteUrl')}
                    />
                  </div>
                  <TextAreaField
                    name={MarketplaceFormKeys.descriptionGenerator_additionalInstructions}
                    title={t('TextForm.Additional Instructions')}
                    css={css({ textarea: { height: '152px' }, padding: 0 })}
                    placeholder={`• ${t('DescriptionGeneratorInfo.placeholder_1')} \n• ${t('DescriptionGeneratorInfo.placeholder_2')} \n• ${t('DescriptionGeneratorInfo.placeholder_3')}`}
                    customError={descriptionErrors.prompt}
                    onChangeCallback={() => resetErrors('prompt')}
                  />
                  <div css={{ display: 'flex', alignSelf: 'flex-end', gap: '8px' }}>
                    <Button
                      title={t('Button.Stop')}
                      variant="orange"
                      disabled={!loading}
                      onClick={() => {
                        onClear();
                        stopPolling();
                        setStatus('idle');
                      }}
                      css={{ minWidth: '94px' }}
                    />
                    <Button
                      title={t('Button.Generate')}
                      variant="black"
                      css={styles.generateButton}
                      onClick={handleStartGeneration}
                      disabled={
                        !(
                          values.descriptionGenerator_additionalInstructions || values.descriptionGenerator_productUrl
                        ) || !isFormValid
                      }
                      loading={{
                        status: loading,
                        showIcon: true,
                        icon: <AiRainbowSpinner css={{ left: 0 }} />,
                        title: <span css={{ width: '30px' }}>{`${progress}%`}</span>
                      }}
                    />
                  </div>
                </div>
              </div>
            }
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

const styles = {
  heading: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }),
  accordionContentBorder: css({
    marginTop: '10px',
    borderRadius: '3px',
    position: 'relative',
    ...setPseudoGradientBorder(0),
    transform: 'translate3d(0, 0, 0)',
    willChange: 'transform'
  }),
  accordionContent: css({
    zIndex: 1,
    display: 'flex',
    flexDirection: 'column',
    rowGap: '16px',
    padding: '24px',
    position: 'relative'
  }),
  outlineMask: css({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: `conic-gradient(from var(--gradient-angle), ${gradients.aiRainbow})`,
    zIndex: -1,
    borderRadius: 'inherit',
    inset: -4,
    opacity: 0.2
  }),
  generatorIconBg: css({
    display: 'flex',
    width: '24px',
    height: '24px',
    background:
      'conic-gradient(from 136deg at 54.7% 53.44%, #5C5BFF 0deg, #15D4FF 144deg, #FFD235 252deg, #FF942E 360deg)'
  }),
  generateButton: css({
    minWidth: '94px',
    ':disabled': {
      background: '#F6F8FA',
      border: '1px solid #DEE5EC',
      color: '#A8B4BF'
    }
  })
};

const setMarkdownFieldsAsync = async (
  generatedDetail: GetGeneratedMarketplaceCampaignDetailPayloadGeneratedDetail,
  setValue: UseFormSetValue<MarketplaceFormValues>,
  setContentForce: DescriptionGeneratorStateReturnType['setContentForce']
) => {
  setValue(MarketplaceFormKeys.title, generatedDetail.title);
  // marked.parse will convert .md to html which Suneditor can edit and our MKP campaigns support
  const serviceInformation = DOMPurify.sanitize(await marked.parse(generatedDetail.serviceInformation));
  const requirement = DOMPurify.sanitize(await marked.parse(generatedDetail.requirement));

  setValue(MarketplaceFormKeys.requirement, requirement);
  setValue(MarketplaceFormKeys.serviceInformation, serviceInformation);
  // Note: force update SunEditor content, because we cannot update content directly from props
  setContentForce(requirement, 'postReq');
  setContentForce(serviceInformation, 'serviceInfo');
};
